.quill .ql-editor {
  min-height: 200px;
}

.rti--container {
  outline: 0 !important;
  border-width: 0 0 1px !important;
  border-color: #d2d6da !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  z-index: 9999;
}

.rti--container:focus {
  border-color: #1a74bb !important;
}

.no-pt-element {
  padding-top: 0 !important;
}

.rti--tag {
  background-color: black;
  color: white;
  border-radius: 25px;
  padding: 5px 3px 5px 10px;
  font-size: 14px;
}

.rti--tag span {
  border-right: 1px solid #aaa;
  padding-right: 7px;
  line-height: 12px;
}

.rti--tag button {
  background-color: black;
  color: #eee;
  padding: 0 7px;
  font-size: 11px;
}

div.dangerousHTMLDIVClass ul {
  list-style-type: disc !important;
}